import { useMemo } from "react"

import axios, { AxiosResponse } from "axios"

import { axiosConfigPrivate } from "api/config"
import { FilesService } from "api/FilesService"
import { useCurrentUserService } from "api/hooks/useCurrentUserService"
import { useDocumentsService } from "api/hooks/useDocumentsService"
import {
  TStaffList,
  TUserActivationData,
} from 'api/hooks/useStaffService/useStaffService.types';
import {
  URL_MANAGERS,
  URL_REQUISITE_TYPES,
  URL_USERS,
  URL_USERS_COMPETENCIES,
  URL_USERS_POSITIONS,
  URL_USERS_ROLES,
  URL_USERS_UPDATE,
  URL_USERS_UPDATE_CONTACT,
  URL_USERS_UPDATE_REQUISITE,
  URL_USERS_UPDATE_SPEC,
} from "api/urlList"
import { useActions } from 'common/hooks/useActions';
import { useProcessResponseError } from 'common/hooks/useProcessResponseError';
import {
  ICreateUserData,
  IUserEditFormOutputData,
} from "models/IUserData"
import { EStaffCategories } from "routes/pages/staff/Staff"
import { useTypedDispatch } from "stores/redux/hooks/useTypedDispatch"
import { setAppGlobalError } from 'stores/redux/slices/app';
import { ICurrentUserDoc } from "stores/redux/slices/current-user/types"
import {
  setStaffClean,
  setStaffClearCurrentUser,
  setStaffClearCurrentUserDocs,
  setStaffCreateResult,
  setStaffCurrentUser,
  setStaffCurrentUserDocs,
  setStaffData,
  setStaffIsLoading,
  setStaffRoles,
  updateCurrentUserDocument,
} from "stores/redux/slices/staff"

const getActiveFilterValue = (filter?: EStaffCategories) => {
  switch (filter) {
    case EStaffCategories.ACTIVE:
      return true;

    case EStaffCategories.INACTIVE:
      return false;

    default:
      return null;
  }
}

export const useStaffService = () => {

  const dispatch = useTypedDispatch()
  const { actions: documentServiceActions } = useDocumentsService()
  const { actions: userService } = useCurrentUserService()
  const { setAppSuccess } = useActions()
  const { processResponseError } = useProcessResponseError()
  
  const actions = useMemo(() => ({
    activateUser: async (data: TUserActivationData): Promise<AxiosResponse> => {
      return axios.patch(`${URL_USERS}/${data.id}`, data, axiosConfigPrivate())
    },
    createUser: async (data: ICreateUserData): Promise<AxiosResponse> => {
      const body = JSON.stringify(data)

      return axios.put(`${URL_USERS}`, body, axiosConfigPrivate())
    },

    fetchData: async (
      searchString = "",
      page = 1,
      per_page = 15,
      filter: { field: string; value: string } = {
        field: "full_name",
        value: "asc",
      },
      activeFilter?: EStaffCategories,
      all_include?: boolean,
      // eslint-disable-next-line max-params
    ): Promise<AxiosResponse<TStaffList>> => {

      const body = JSON.stringify({
        active: getActiveFilterValue(activeFilter),
        all_include: typeof all_include === 'boolean' ? all_include : activeFilter === EStaffCategories.ALL,
        page: page,
        per_page: per_page,
        search_by: searchString,
        sorting: [ {
          direction: filter.value,
          field: filter.field,
        } ],
      })

      return axios.post(URL_USERS, body, axiosConfigPrivate())
    },

    fetchManagers: async (): Promise<AxiosResponse> => {
      const body = JSON.stringify({ rolesIds: [ 2, 3 ] })

      return axios.post(URL_MANAGERS, body, axiosConfigPrivate())
    },

    fetchStaffBanks: async (): Promise<AxiosResponse> => {
      return axios.get(`${URL_REQUISITE_TYPES}`, axiosConfigPrivate())
    },

    fetchStaffCompetencies: async (): Promise<AxiosResponse> => {
      return axios.get(URL_USERS_COMPETENCIES, axiosConfigPrivate())
    },

    fetchStaffForSelect: async (): Promise<AxiosResponse> => {
      const body = JSON.stringify({
        all_include: false,
        sorting: [ {
          direction: "asc",
          field: "full_name",
        } ],
      })

      return axios.post(URL_USERS, body, axiosConfigPrivate())
    },

    fetchStaffPositions: async (): Promise<AxiosResponse> => {
      return axios.get(URL_USERS_POSITIONS, axiosConfigPrivate())
    },

    fetchStaffRoles: async (): Promise<AxiosResponse> => {
      return axios.get(URL_USERS_ROLES, axiosConfigPrivate())
    },

    updateStaffContacts: async (
      id: number,
      type: number,
      value: string,
      user_id: string,
      // eslint-disable-next-line max-params
    ): Promise<AxiosResponse> => {
      const body = JSON.stringify({
        id: id,
        type_id: type,
        value: value,
      })

      return axios.patch(`${URL_USERS_UPDATE_CONTACT}/${user_id}`, body, axiosConfigPrivate())
    },

    updateStaffPersonalAvatar: async (userId: number, avatarId: number): Promise<AxiosResponse> => {
      const body = JSON.stringify({ avatar_id: avatarId })
      return axios.patch(`${URL_USERS_UPDATE}/${userId}`, body, axiosConfigPrivate())
    },

    updateStaffPersonalData: async (data: IUserEditFormOutputData): Promise<AxiosResponse> => {
      const body = JSON.stringify({
        birth_date: data.birth_date,
        first_name: data.first_name,
        id: parseInt(data.user_id),
        middle_name: data.middle_name,
        note: data.note,
        residence_address: data.residence_address,
        resume_link: data.resume_link ? data.resume_link : null,
        second_name: data.second_name,
      })

      return axios.patch(`${URL_USERS_UPDATE}/${data.user_id}`, body, axiosConfigPrivate())
    },

    updateStaffRequisite: async (data: IUserEditFormOutputData): Promise<AxiosResponse> => {
      const body = JSON.stringify({ ...data.requisites })

      return axios.patch(`${URL_USERS_UPDATE_REQUISITE}/${data.user_id}`,
        body,
        axiosConfigPrivate())
    },

    updateStaffSpecialization: async (data: IUserEditFormOutputData,
      id: string): Promise<AxiosResponse> => {
      const body = JSON.stringify(data)
      return axios.patch(`${URL_USERS_UPDATE_SPEC}/${id}`, body, axiosConfigPrivate())
    },
  }), [])

  return {
    actions,
    activateHandlerUser: async (data: TUserActivationData) => {
      try {
        const response = await actions.activateUser(data)

        if (response.status === 200 || response.status === 201) {
          if (data.active){
            setAppSuccess("Пользователь успешно активирован")
          } else {
            setAppSuccess("Пользователь успешно деактивирован")
          }

          return true;
        } else {
          processResponseError(response)
          return false;
        }
      } catch (err) {
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
        return false;
      }
    },
    createUser: async (data: ICreateUserData) => {
      try {
        dispatch(setStaffIsLoading(true))

        const response = await actions.createUser(data)

        if (response.status === 201) {
          dispatch(setStaffCreateResult(true))
        } else {
          dispatch(setStaffClean())
          processResponseError(response)
        }
      } catch (err) {
        dispatch(setStaffClean())
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      } finally {
        dispatch(setStaffIsLoading(false))
      }
    },
    fetchStaff:
        async (
          searchString?: string,
          page?: number,
          per_page?: number,
          filter?: { field: string; value: string },
          activeFilter?: EStaffCategories,
          // eslint-disable-next-line max-params
        ) => {
          try {
            dispatch(setStaffClean())
            dispatch(setStaffIsLoading(true))

            const response = await actions.fetchData(
              searchString,
              page,
              per_page,
              filter,
              activeFilter,
            )

            if (response.status === 200 && response.data) {
              dispatch(setStaffData(response.data))
            } else {
              dispatch(setStaffClean())
              processResponseError(response)
            }
          } catch (e) {
            dispatch(setStaffClean())
            dispatch(setAppGlobalError({
              message: [ "" ],
              status: 500,
            }))
          } finally {
            dispatch(setStaffIsLoading(false))
          }
        },
    fetchStaffRoles: async () => {
      try {
        const response = await actions.fetchStaffRoles()

        if (response.status === 200 || response.status === 201) {
          dispatch(setStaffRoles(response.data))
        } else {
          processResponseError(response)
        }
      } catch (err) {
        dispatch(setStaffClean())
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      }
    },
    fetchUserData: async (userId: string) => {
      try {
        dispatch(setStaffIsLoading(true))
        dispatch(setStaffClearCurrentUser())
        dispatch(setStaffClearCurrentUserDocs())

        const response = await userService.fetchUserData(parseInt(userId))

        const verifiedDocs = await documentServiceActions.fetchDocumentsByUserId(parseInt(userId), "verified")
        const unverifiedDocs = await documentServiceActions.fetchDocumentsByUserId(parseInt(userId), "unverified")
        const refusedDocs = await documentServiceActions.fetchDocumentsByUserId(parseInt(userId), "refused")

        if (response.status === 200 || response.status === 201) {
          const {
            active,
            contacts,
            email,
            id,
            personal,
            requisites,
            specialization,
          } =
              response.data

          let avatar = {
            id: 0,
            url: "",
          }
          if (personal?.avatar) {
            const imageResponse = await FilesService.getFile(personal.avatar.id, "blob")
            const imageBase64 = URL.createObjectURL(imageResponse.data)
            avatar = {
              id: personal.avatar.id,
              url: `${imageBase64}`,
            }
          }

          let docs: Array<ICurrentUserDoc> = []
          if (
            verifiedDocs.status === 200 &&
              unverifiedDocs.status === 200 &&
              refusedDocs.status === 200
          ) {
            docs = [
              ...verifiedDocs.data,
              ...unverifiedDocs.data,
              ...refusedDocs.data,
            ]
          }

          dispatch(setStaffCurrentUser({
            active: active,
            avatar: {
              id: avatar.id,
              url: avatar.url,
            },
            birth_date: personal && personal.birth_date,
            contacts: contacts,
            email: email,
            first_name: personal && personal.first_name,
            id: id,
            middle_name: personal && personal.middle_name,
            note: personal && personal.note,
            requisites: requisites,
            residence_address: personal && personal.residence_address,
            resume_link: personal && personal.resume_link,
            second_name: personal && personal.second_name,
            specialization: specialization,
          }))

          dispatch(setStaffCurrentUserDocs(docs))
        } else {
          dispatch(setStaffClean())
          processResponseError(response)
        }
      } catch (e) {
        dispatch(setStaffClean())
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      } finally {
        dispatch(setStaffIsLoading(false))
      }
    },
    setDocumentVerification:
        async (
          id: number, verified: boolean, reason: string, callback: () => void,
          // eslint-disable-next-line max-params
        ) => {
          try {
            const response = await documentServiceActions.setVerification(id, verified, reason)

            if (response.status === 200 || response.status === 201) {
              callback()
              dispatch(updateCurrentUserDocument(response.data))
            } else {
              processResponseError(response)
            }
          } catch (e) {
            dispatch(setAppGlobalError({
              message: [ "" ],
              status: 500,
            }))
          } finally {
            dispatch(setStaffIsLoading(false))
          }
        },
  };
}
