import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import { ConfigProvider } from "antd"
import dayjs from "dayjs"

import locale from "antd/es/locale/ru_RU"
import { saveUserUrl } from 'app/save-user-url';
import { HistoryCtxProvider } from 'common/context/HistoryCtx';
import { NotificationHandler } from "common/hoc/notification-handler/NotificationHandler"
import updateLocale from "dayjs/plugin/updateLocale";
import { ErrorBoundaryCritical } from "routes/wrappers/ErrorBoundary/critical/ErrorBoundaryCritical"
import { RoutesWrapper } from "routes/wrappers/RoutesWrapper/RoutesWrapper"
import { reduxStore } from "stores/redux"

import "dayjs/locale/ru"

import "antd/dist/antd.css"

import "styles/index.scss"
import "styles/libs/_reqct_quill_fix.css"

import "react-phone-input-2/lib/style.css"
import "antd/es/date-picker/style/index"
import "react-quill/dist/quill.snow.css"
import "quill1.3.7-table-module/dist/index.css"

import "dayjs/locale/ru";

dayjs.extend(updateLocale)
dayjs.updateLocale("ru", { weekStart: 1 /* начало недели - понедельник */ });

/**
 * Фиксируем url, по которому юзер перешел в приложение.
 * После авторизации перенаправим юзера по этой ссылке
 * в ту точку приложения, в которую юзер хотел попасть.
 */
saveUserUrl();

export const App = () => {

  return (
    <ErrorBoundaryCritical>
      <Provider store={reduxStore}>
        <BrowserRouter>
          <ConfigProvider locale={locale}>
            <NotificationHandler>
              <HistoryCtxProvider>
                <RoutesWrapper />
              </HistoryCtxProvider>
            </NotificationHandler>
          </ConfigProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundaryCritical>
  );
}
