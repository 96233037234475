import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { TPartnerListItem } from "api/hooks/usePartnersService/usePartnersService.types"
import { IPartnersState } from "stores/redux/slices/partners/types"

const initialState: IPartnersState = {
  createResult: false,
  currentPartnerData: {
    chat_link: '',
    company_name: '',
    id: 0,
  },
  data: [],
  deleteResult: false,
  isLoading: false,
}

export const partnersSlice = createSlice({
  initialState,
  name: 'partners',
  reducers: {
    resetStorePartners(state: IPartnersState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IPartnersState]);
    },
    setPartnersClean(state: IPartnersState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IPartnersState]);
    },
    setPartnersCreateResult(state: IPartnersState, payload: PayloadAction<boolean>) {
      state.createResult = payload.payload
    },
    setPartnersData(state: IPartnersState, payload: PayloadAction<TPartnerListItem[]>) {
      state.data = payload.payload

      state.isLoading = false
    },
    setPartnersDeleteResult(state: IPartnersState, payload: PayloadAction<boolean>) {
      state.deleteResult = payload.payload
    },
    setPartnersFormData(state: IPartnersState, payload: PayloadAction<TPartnerListItem>) {
      state.currentPartnerData = payload.payload
    },
    setPartnersIsLoading(state: IPartnersState, payload: PayloadAction<boolean>) {
      state.isLoading = payload.payload
    },
  },
})

export const {
  resetStorePartners,
  setPartnersClean,
  setPartnersCreateResult,
  setPartnersData,
  setPartnersDeleteResult,
  setPartnersFormData,
  setPartnersIsLoading,
} = partnersSlice.actions

export default partnersSlice.actions