import React from 'react'
import { FC } from "react"
import { useNavigate } from "react-router-dom"

import {
  Col,
  Row,
  Table,
} from "antd"

import { LoadingOutlined } from "@ant-design/icons"
import {
  TStaffList,
  IStaffUserData,
  TStaffListItemPersonal,
} from 'api/hooks/useStaffService/useStaffService.types';
import { AvatarComponent } from "common/components/staff/AvatarComponent/AvatarComponent"
import styles from "common/components/staff/table/ListTable.module.scss"
import { TContact } from "models/IUserData"
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import { getFormatBirthDate } from "utils/date"
import {
  getContactInfo,
  getPositionInfo,
} from "utils/user"
import { RouteNames } from "variables/routes"


interface IListTableProps {
    data: TStaffList
    fetchData: (page: number, per_page?: number) => void
    loading: boolean
}

export const ListTable: FC<IListTableProps> = props => {
  const { data: {
    pagination,
    users,
  } } = props
  const { id } = useTypedSelector(state => state.currentUser)

  const navigate = useNavigate()

  const columns = [
    {
      dataIndex: "personal",
      key: "personal",
      render: (personal: TStaffListItemPersonal) => <AvatarComponent personal={personal} />,
      title: <span className={styles.columnTitle}>Аватар</span>,
    },
    {
      dataIndex: "personal",
      key: "personal",
      render: (personal: TStaffListItemPersonal) => (
        <span>
          {
            personal
              ? `${personal.second_name} ${personal.first_name} ${personal.middle_name}`
              : "-"
          }
        </span>
      ),
      title: <span className={styles.columnTitle}>ФИО</span>,
    },
    {
      dataIndex: "specialization",
      key: "specialization",
      render: (specialization: IStaffUserData["specialization"]) => (
        <span>{getPositionInfo(specialization?.positions, "-")}</span>
      ),
      title: <span className={styles.columnTitle}>Должность</span>,
    },
    {
      dataIndex: "specialization",
      key: "specialization_level",
      render: (specialization: IStaffUserData["specialization"]) => (
        <span>{specialization?.level?.level_name || '-'}</span>
      ),
      title: <span className={styles.columnTitle}>Уровень</span>,
    },
    {
      dataIndex: "personal",
      key: "personal",
      render: (personal: TStaffListItemPersonal) => (
        <span>{personal ? getFormatBirthDate(personal.birth_date) : "-"}</span>
      ),
      title: <span className={styles.columnTitle}>Дата рождения</span>,
    },
    {
      dataIndex: "email",
      key: "email",
      title: <span className={styles.columnTitle}>E-mail</span>,
    },
    {
      dataIndex: "contacts",
      key: "contacts",
      render: (contacts: TContact[]) => <span>{getContactInfo(contacts, 4, "-")}</span>,
      title: <span className={styles.columnTitle}>Телефон</span>,
    },
  ]

  return (
    <div className={styles.listTableContainer}>
      <Row
        justify="center"
        style={{ width: "100%" }}
      >
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={users}
            loading={
              {
                indicator: <LoadingOutlined />,
                size: "large",
                spinning: props.loading,
              }
            }
            onRow={
              record => {
                return { onClick: () => {
                  if (`${record.id}` === `${id}`) {
                    navigate(RouteNames.Profile)
                  } else {
                    navigate(`${RouteNames.UserStatic}/${record.id}`)
                  }
                } }
              }
            }
            pagination={
              {
                current: pagination?.current_page || 1,
                defaultCurrent: 1,
                defaultPageSize: 15,
                onChange: (page, per_page) => props.fetchData(page, per_page),
                onShowSizeChange: (page, per_page) => props.fetchData(page, per_page),
                pageSizeOptions: [ "15", "30" ],
                position: [ "bottomLeft" ],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} из ${total} сотрудников`,
                total: pagination.total,
              }
            }
            rowClassName={styles.row}
            rowKey={"id"}
            scroll={{ x: 1000 }}
          />
        </Col>
      </Row>
    </div>
  )
}
