import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import {
  IUserData,
  TCompetence,
  TLevel,
  TPosition,
} from "models/IUserData"
import {
  IStaffEditState,
  TBank,
} from "stores/redux/slices/staff-edit/types"


const initialState: IStaffEditState = {
  banks: [],
  competencies: [],
  isLoading: false,
  position_levels: [],
  positions: [],
  success: false,
  userData: {
    avatar: {
      id: 0,
      url: "",
    },
    birth_date: "",
    contacts: [],
    email: "",
    first_name: "",
    id: 0,
    middle_name: "",
    note: "",
    requisites: null,
    residence_address: "",
    resume_link: null,
    second_name: "",
    specialization: undefined,
  },
}

export const staffEditSlice = createSlice({
  initialState,
  name: 'staff-edit',
  reducers: {
    resetStoreStaffEdit(state: IStaffEditState) {
      Object.keys(state).forEach(key => (state as unknown as { [k in string]: unknown })[key] = initialState[key as keyof IStaffEditState]);
    },
    setStaffEditLoading(state: IStaffEditState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setStaffEditMetaData(state: IStaffEditState, action: PayloadAction<{
      banks: TBank[],
      competencies: TCompetence[],
      position_levels: TLevel[],
      positions: TPosition[]
    }>) {
      state.banks = action.payload.banks
      state.competencies = action.payload.competencies
      state.positions = action.payload.positions
      state.position_levels = action.payload.position_levels
    },
    setStaffEditSuccess(state: IStaffEditState, action: PayloadAction<boolean>) {
      state.success = action.payload
    },
    setStaffEditUserData(state: IStaffEditState, action: PayloadAction<IUserData>) {
      state.userData = action.payload
    },
  },
});

export const {
  resetStoreStaffEdit,
  setStaffEditLoading,
  setStaffEditMetaData,
  setStaffEditSuccess,
  setStaffEditUserData,
} = staffEditSlice.actions;

export default staffEditSlice.actions;
