import {
  isDayjs,
  locale,
} from 'dayjs';

import {
  configureStore,
  isPlain,
} from '@reduxjs/toolkit';
import { SerializableStateInvariantMiddlewareOptions } from '@reduxjs/toolkit/src/serializableStateInvariantMiddleware';
import { appSlice } from "stores/redux/slices/app"
import { authSlice } from "stores/redux/slices/auth"
import { clientsSlice } from "stores/redux/slices/clients"
import { clientsEditSlice } from "stores/redux/slices/clients-edit"
import { userSlice } from "stores/redux/slices/current-user"
import { leadsSlice } from "stores/redux/slices/leads"
import { leadsEditSlice } from "stores/redux/slices/leads-edit"
import { optionsDocumentsSlice } from "stores/redux/slices/options-documents"
import { optionsReferencesSlice } from "stores/redux/slices/options-references"
import { partnersSlice } from 'stores/redux/slices/partners'
import { staffSlice } from "stores/redux/slices/staff"
import { staffEditSlice } from "stores/redux/slices/staff-edit"
import { vacationSlice } from "stores/redux/slices/vacation"
import { widgetsSlice } from "stores/redux/slices/widgets"
import { wikiSlice } from "stores/redux/slices/wiki"

const isSerializable = (value: unknown) => isPlain(value) || isDayjs(value) || value instanceof Date || value instanceof locale;

const getEntries: (value: unknown) => [string, unknown][] = (value: unknown) =>
  isDayjs(value) ? Object.entries(value).filter(([ key ]) => !key.startsWith('_')) : Object.entries(value as Record<string, unknown>);

const serializableCheck: SerializableStateInvariantMiddlewareOptions = {
  getEntries,
  isSerializable,
};

export const reduxStore = configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck }),
  reducer: {
    app: appSlice.reducer,
    auth: authSlice.reducer,
    clients: clientsSlice.reducer,
    clientsEdit: clientsEditSlice.reducer,
    currentUser: userSlice.reducer,
    leads: leadsSlice.reducer,
    leadsEdit: leadsEditSlice.reducer,
    optionsDocuments: optionsDocumentsSlice.reducer,
    optionsReferences: optionsReferencesSlice.reducer,
    partners: partnersSlice.reducer,
    staff: staffSlice.reducer,
    staffEdit: staffEditSlice.reducer,
    vacation: vacationSlice.reducer,
    widgets: widgetsSlice.reducer,
    wiki: wikiSlice.reducer,
  },
});

export type TReduxRootState = ReturnType<typeof reduxStore.getState>;
export type TReduxDispatch = typeof reduxStore.dispatch;

export const reduxActions = {
  app: appSlice.actions,
  auth: authSlice.actions,
  clients: clientsSlice.actions,
  clientsEdit: clientsEditSlice.actions,
  currentUser: userSlice.actions,
  leads: leadsSlice.actions,
  leadsEdit: leadsEditSlice.actions,
  optionsDocuments: optionsDocumentsSlice.actions,
  optionsReferences: optionsReferencesSlice.actions,
  partners: partnersSlice.actions,
  staff: staffSlice.actions,
  staffEdit: staffEditSlice.actions,
  vacation: vacationSlice.actions,
  widgets: widgetsSlice.actions,
  wiki: wikiSlice.actions,
};

export const reduxAllActions = {
  ...appSlice.actions,
  ...authSlice.actions,
  ...clientsSlice.actions,
  ...clientsEditSlice.actions,
  ...userSlice.actions,
  ...leadsSlice.actions,
  ...leadsEditSlice.actions,
  ...optionsDocumentsSlice.actions,
  ...optionsReferencesSlice.actions,
  ...partnersSlice.actions,
  ...staffSlice.actions,
  ...staffEditSlice.actions,
  ...vacationSlice.actions,
  ...widgetsSlice.actions,
  ...wikiSlice.actions,
};
