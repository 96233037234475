import React, {
  useMemo,
  useState,
} from 'react';
import { useLocation } from "react-router"
import { Link } from "react-router-dom"

import {
  Menu,
  Skeleton,
} from "antd"

import {
  TeamOutlined,
  AuditOutlined,
  SettingOutlined,
  ReadOutlined,
  SolutionOutlined,
  FileTextOutlined,
  HomeOutlined,
  FormOutlined,
  CalendarOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons"
import { MenuProps } from "antd/es"
import Sider from "antd/es/layout/Sider"
import { ReactComponent as BriefcaseOutlined } from 'assets/images/svg/outlined/briefcase.svg'
import styles from "common/components/aside/Sider.module.scss"
import { usePermissions } from 'common/hooks/usePermissions';
import { useWindowSize } from "common/hooks/useWindowSize"
import { isNoAuth } from "config/env"
import { useTypedSelector } from "stores/redux/hooks/useTypedSelector"
import {
  enableHorizontalScrolling,
  disableHorizontalScrolling,
} from "utils/ui"
import {
  RouteNames,
  routesForEveryone,
  routesForManager,
} from 'variables/routes';

const siderItemIconStyle: React.CSSProperties = {
  height: '14px',
  width: '14px',
}

const SidebarMenuSkeleton = () => {

  const items: MenuProps['items'] = Array(3)
    .fill(null)
    .map((_, i) => ({
      key: `SidebarMenuSkeleton-item-${i}`,
      label: <Skeleton.Button active />,
    }))

  return (
    <Menu
      className={styles.menu}
      items={items}
      style={{ height: "100vh" }}
      theme="dark"
    />
  );
}

export const Sidebar = () => {

  const {
    isLoading,
    roles,
  } = useTypedSelector(state => state.auth)

  const isAuthLoading = isLoading && !isNoAuth()

  const {
    isAdmin,
    isManagerOnly,
  } = usePermissions()


  const [ isCollapsed, setIsCollapsed ] = useState(false)
  const location = useLocation();
  const { width } = useWindowSize()

  const onCollapse = (collapsed: boolean) => {
    if (collapsed) {
      disableHorizontalScrolling()
    } else {
      enableHorizontalScrolling()
    }

    setIsCollapsed(collapsed)
  }

  const setAsideWidth = (w: number): number => {
    if (w > 767) {
      return 240
    } else if (w > 480) {
      return 220
    } else {
      return w
    }
  }

  const asideWidth = useMemo(() => setAsideWidth(width), [ width ])

  const menuItems: MenuProps['items'] = useMemo(() => {

    let items: MenuProps['items'] = [
      {
        icon: <HomeOutlined />,
        key: RouteNames.Home,
        label: <Link to={RouteNames.Home}>Главная</Link>,
      },
      {
        icon: <FormOutlined />,
        key: RouteNames.Wiki,
        label: <Link to={RouteNames.Wiki}>Wiki</Link>,
      },
      {
        icon: <TeamOutlined />,
        key: RouteNames.UserList,
        label: <Link to={RouteNames.UserList}>Сотрудники</Link>,
      },
      {
        icon: <CalendarOutlined />,
        key: RouteNames.Vacation,
        label: <Link to={RouteNames.Vacation}>График отпусков</Link>,
      },
      {
        icon: <AuditOutlined />,
        key: RouteNames.Leads,
        label: <Link to={RouteNames.Leads}>Лиды</Link>,
      },
      {
        icon: <SolutionOutlined />,
        key: RouteNames.Clients,
        label: <Link to={RouteNames.Clients}>Клиенты</Link>,
      },
      {
        children: [ {
          icon: <UnorderedListOutlined />,
          key: RouteNames.Partners,
          label: <Link to={RouteNames.Partners}>Список</Link>,
        } ],
        icon: <BriefcaseOutlined style={siderItemIconStyle} />,
        key: "partners",
        label: "Партнеры",
      },
      {
        children: [ {
          icon: <ReadOutlined />,
          key: RouteNames.References,
          label: <Link to={RouteNames.References}>Справочники</Link>,
        }, {
          icon: <FileTextOutlined />,
          key: RouteNames.Documents,
          label: <Link to={RouteNames.Documents}>Документы</Link>,
        } ],
        icon: <SettingOutlined />,
        key: "options",
        label: "Настройки",
      },
    ]

    // if ((!isNoAuth() && isManagerOnly) || isNoAuth()){
    //   if (!items.find(item => item?.key === RouteNames.Leads)){
    //     items.push({
    //       icon: <AuditOutlined />,
    //       key: RouteNames.Leads,
    //       label: <Link to={RouteNames.Leads}>Лиды</Link>,
    //     })
    //   }
    //
    //   if (!items.find(item => item?.key === RouteNames.Clients)){
    //     items.push({
    //       icon: <SolutionOutlined />,
    //       key: RouteNames.Clients,
    //       label: <Link to={RouteNames.Clients}>Клиенты</Link>,
    //     })
    //   }
    // }

    // if ((!isNoAuth() && isAdmin) || isNoAuth()){
    //   if (!items.find(item => item?.key === "options")){
    //     items.push({
    //       children: [ {
    //         icon: <ReadOutlined />,
    //         key: RouteNames.References,
    //         label: <Link to={RouteNames.References}>Справочники</Link>,
    //       }, {
    //         icon: <FileTextOutlined />,
    //         key: RouteNames.Documents,
    //         label: <Link to={RouteNames.Documents}>Документы</Link>,
    //       } ],
    //       icon: <SettingOutlined />,
    //       key: "options",
    //       label: "Настройки",
    //     })
    //   }
    // }

    /* отображается всё при сборке без авторизации */
    if (isNoAuth()){
      return items;
    }

    /* менеджер */
    if (isManagerOnly){
      return items.filter(item => item?.key && routesForManager.includes(item.key as RouteNames));
    }

    /* сотрудник */
    if (!isAdmin){
      return items.filter(item => item?.key && routesForEveryone.includes(item.key as RouteNames));
    }

    /* админ */
    return items;
  }, [ isAdmin, isManagerOnly ]);

  return (
    <Sider
      breakpoint="md"
      collapsed={isCollapsed}
      collapsedWidth={0}
      collapsible
      onClick={
        (item: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
          if (item.target.localName === "a" && width < 480) {
            onCollapse(true)
          }
        }
      }
      onCollapse={onCollapse}
      width={asideWidth}
    >
      {
        ((!isNoAuth() && roles.length > 0) || isNoAuth()) && !isAuthLoading && (
          <Menu
            items={menuItems}
            mode="inline"
            selectedKeys={[ location.pathname ]}
            theme="dark"
          />
        )
      }

      {
        ((!isNoAuth() && roles.length <= 0) || isAuthLoading) && (
          <SidebarMenuSkeleton />
        )
      }
    </Sider>
  )
}
