import React from 'react'

import Tag from "antd/es/tag"
import {
  TCompetence,
  TContact,
  TPosition,
  TRequisites,
} from "models/IUserData"


export const getContactId = (contacts: TContact[] | null | undefined, id: number): number => {
  return contacts?.find(item => item.type.id === id)?.id || 0
}

export const getCompetenciesIds = (competencies: TCompetence[] | null | undefined) => {
  return competencies?.filter(item => item.active).map(item => item.id) || []
}

export const getContactInfo = (contacts: TContact[] | null | undefined,
  id: number,
  // eslint-disable-next-line max-params
  placeholder: string) => {
  return contacts?.find(item => item.type.id === id)?.value || placeholder
}

export const getPositionId = (positions: TPosition[] | null | undefined) => {
  if (!positions?.length){
    return undefined;
  }

  return positions[0].id
}

export const getPositionInfo = (positions: TPosition[] | null | undefined, placeholder = 'Должность') => {
  if (!positions?.length){
    return placeholder;
  }

  return positions[0].description
}

export const getCompetenciesInfo = (competencies: TCompetence[] | null | undefined,
  placeholder: string) => {
  if (competencies?.length) {
    return competencies
      .filter(item => item.active)
      .map((item, index: number) => <Tag key={index}>{item.description}</Tag>)
  }
  return placeholder
}

export const getRequisitesInfo = (requisites: TRequisites | null | undefined) => {
  if (!requisites) {
    return "-"
  }

  return (
    <div>
      <Tag>Номер счета: {requisites.account_number || "-"}</Tag>

      <Tag>Номер карты: {requisites.card_number || "-"}</Tag>

      <Tag>Банк получателя: {requisites.bank_name || "-"}</Tag>

      <Tag>БИК: {requisites.bank_bik || "-"}</Tag>

      <Tag>ИНН Банка: {requisites.bank_inn || "-"}</Tag>

      <Tag>КПП Банка: {requisites.bank_kpp || "-"}</Tag>

      <Tag>Кор. счёт: {requisites.corporate_account || "-"}</Tag>
    </div>
  )
}
