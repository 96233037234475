import { useMemo } from "react"

import axios, { AxiosResponse } from "axios"

import { axiosConfigPrivate } from "api/config"
import { TApiServiceActionDeleteResponse } from 'api/types/common';
import {
  TLevel,
  TLevelDelete,
  TLevelPatch,
  TLevelPut,
  TLevelApi,
} from 'api/types/specializations';
import { URL_SPECIALIZATION_LEVELS } from "api/urlList"
import { useProcessResponseError } from 'common/hooks/useProcessResponseError';
import { useTypedDispatch } from "stores/redux/hooks/useTypedDispatch"
import {
  setAppGlobalError,
  setAppSuccess,
} from 'stores/redux/slices/app';


export const useSpecializationLevelsService = () => {

  const dispatch = useTypedDispatch()
  const { processResponseError } = useProcessResponseError()

  const actions = useMemo(() => ({
    deleteLevel: async ({ id }: TLevelDelete): Promise<AxiosResponse<TApiServiceActionDeleteResponse>> => {
      return axios.delete(`${URL_SPECIALIZATION_LEVELS}/${id}`, axiosConfigPrivate())
    },

    getLevels: async (): Promise<AxiosResponse<TLevelApi[]>> => {
      return axios.get(URL_SPECIALIZATION_LEVELS, axiosConfigPrivate())
    },

    patchLevel: async (data: TLevelPatch): Promise<AxiosResponse<TLevelApi>> => {
      return axios.patch(`${URL_SPECIALIZATION_LEVELS}/${data.id}`, JSON.stringify({ level_name: data.description }), axiosConfigPrivate())
    },

    putLevel: async (data: TLevelPut): Promise<AxiosResponse<TLevelApi>> => {
      return axios.post(URL_SPECIALIZATION_LEVELS, JSON.stringify({
        ...data,
        level_name: data.description,
      }), axiosConfigPrivate())
    },
  }), [])


  return {
    actions,
    createLevel: async (data: TLevelPut): Promise<TLevel | undefined> => {
      try {
        const response = await actions.putLevel(data)

        if (response.status === 201 || response.status === 200) {
          dispatch(setAppSuccess("Уровень успешно создан."))
          return {
            ...response.data,
            description: response.data.level_name,
          }
        } else {
          processResponseError(response)
        }
      } catch (err) {
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      }
    },

    deleteLevel: async (data: TLevelDelete): Promise<TApiServiceActionDeleteResponse | undefined> => {
      try {
        const response = await actions.deleteLevel(data)

        if (response.status === 201 || response.status === 200) {
          dispatch(setAppSuccess("Уровень успешно удален."))
          return response.data;
        } else {
          processResponseError(response)
        }
      } catch (err) {
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      }
    },

    editLevel: async (data: TLevelPatch): Promise<TLevel | undefined> => {
      try {
        const response = await actions.patchLevel(data)

        if (response.status === 201 || response.status === 200) {
          dispatch(setAppSuccess("Уровень успешно отредактирован."))
          return {
            ...response.data,
            description: response.data.level_name,
          }
        } else {
          processResponseError(response)
        }
      } catch (err) {
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      }
    },


    fetchLevels: async (): Promise<TLevel[] | undefined> => {
      try {
        const response = await actions.getLevels()

        if (response.status === 200) {
          return response.data.map(level => ({
            ...level,
            description: level.level_name,
          }))
        }

        processResponseError(response)
      } catch (err) {
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      }

      return undefined;
    },
  };

}
