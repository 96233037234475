import { FilesService } from "api/FilesService"
import { useAuthService } from 'api/hooks/useAuthService';
import { useCurrentUserService } from "api/hooks/useCurrentUserService"
import { useSpecializationLevelsService } from "api/hooks/useSpecializationService/services/useSpecializationLevelsService"
import { useStaffService } from "api/hooks/useStaffService"
import { useProcessResponseError } from 'common/hooks/useProcessResponseError';
import { IUserEditFormOutputData } from "models/IUserData"
import { useTypedDispatch } from "stores/redux/hooks/useTypedDispatch"
import { setAppGlobalError } from "stores/redux/slices/app"
import {
  resetStoreStaffEdit,
  setStaffEditLoading,
  setStaffEditMetaData,
  setStaffEditSuccess,
  setStaffEditUserData,
} from "stores/redux/slices/staff-edit"


const validateSpecializationValues = (values: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
  let passedValues: any = {} /* eslint-disable-line @typescript-eslint/no-explicit-any */

  if (values.position) {
    passedValues["position_id"] = values.position
  }

  if (values.competencies[0]) {
    passedValues["competencies"] = values.competencies
  } else {
    passedValues["competencies"] = []
  }

  for (const [ key, value ] of Object.entries(values)) {
    if (value && key !== "positions" && key !== "competencies") {
      passedValues[key] = value
    }
  }

  return passedValues
}

export const useStaffEditService = () => {

  const dispatch = useTypedDispatch()
  const { actions: staffServiceActions } = useStaffService()
  const { patchUserRole } = useAuthService()
  const { actions: userService } = useCurrentUserService()
  const { processResponseError } = useProcessResponseError()
  const { actions: staffSpecializationActions } = useSpecializationLevelsService()

  return {
    fetchStaffEditMetaData: async () => {
      try {
        let metaData = {
          banks: [],
          competencies: [],
          position_levels: [],
          positions: [],
        }

        let response = await staffServiceActions.fetchStaffPositions()
        if (response.status === 200 || response.status === 201) {
          metaData.positions = response.data
        } else {
          processResponseError(response)
        }

        response = await staffServiceActions.fetchStaffCompetencies()
        if (response.status === 200 || response.status === 201) {
          metaData.competencies = response.data
        } else {
          processResponseError(response)
        }

        /*response = await staffServiceActions.fetchStaffBanks()
        if (response.status === 200 || response.status === 201) {
          metaData.banks = response.data
        } else {
          processResponseError(response)
        }*/

        response = await staffSpecializationActions.getLevels()
        if (response.status === 200 || response.status === 201) {
          metaData.position_levels = response.data
        } else {
          processResponseError(response)
        }

        dispatch(setStaffEditMetaData(metaData))
      } catch (e) {
        dispatch(resetStoreStaffEdit())
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      }
    },
    fetchStaffEditUserData: async (userId: string) => {
      try {
        dispatch(resetStoreStaffEdit())
        dispatch(setStaffEditLoading(true))

        const response = await userService.fetchUserData(parseInt(userId))

        if (response.status === 200 || response.status === 201) {
          const {
            contacts,
            email,
            id,
            personal,
            requisites,
            specialization,
          } = response.data

          let avatar = {
            id: 0,
            url: "",
          }
          if (personal?.avatar) {
            const imageResponse = await FilesService.getFile(personal.avatar.id, "blob")
            const imageBase64 = URL.createObjectURL(imageResponse.data)
            avatar = {
              id: personal.avatar.id,
              url: `${imageBase64}`,
            }
          }

          dispatch(setStaffEditUserData({
            avatar: {
              id: avatar.id,
              url: avatar.url,
            },
            birth_date: personal && personal.birth_date,
            contacts: contacts,
            email: email,
            first_name: personal && personal.first_name,
            id: id,
            middle_name: personal && personal.middle_name,
            note: personal && personal.note,
            requisites: requisites,
            residence_address: personal && personal.residence_address,
            resume_link: personal && personal.resume_link,
            second_name: personal && personal.second_name,
            specialization: specialization,
          }))
        } else {
          dispatch(resetStoreStaffEdit())
          processResponseError(response)
        }
      } catch (e) {
        dispatch(resetStoreStaffEdit())
        dispatch(setAppGlobalError({
          message: [ "" ],
          status: 500,
        }))
      } finally {
        dispatch(setStaffEditLoading(false))
      }
    },
    updateStaff: async (data: IUserEditFormOutputData) => {
      try {
        let response = await staffServiceActions.updateStaffPersonalData(data)
        if (response.status !== 200) {
          processResponseError(response)
          throw new Error("upd_error")
        }

        const specValues = validateSpecializationValues({
          amount: data.amount,
          competencies: data.competencies,
          level_id: data.position_level,
          position: data.position,
          work_from: data.work_from,
          work_hours: data.work_hours,
          work_to: data.work_to,
        })

        if (
          specValues &&
            Object.keys(specValues).length !== 0 &&
            Object.getPrototypeOf(specValues) === Object.prototype
        ) {
          response = await staffServiceActions.updateStaffSpecialization(specValues, data.user_id)
          if (response.status !== 201 && response.status !== 200) {
            processResponseError(response)
            throw new Error("upd_error")
          }
        }

        response = await staffServiceActions.updateStaffContacts(
          data.phone_id,
          4,
          data.telephone,
          data.user_id,
        )

        if (response.status > 201) {
          processResponseError(response)
          throw new Error("upd_error")
        }

        response = await staffServiceActions.updateStaffContacts(
          data.skype_id,
          2,
          data.skype,
          data.user_id,
        )

        if (response.status > 201) {
          processResponseError(response)
          throw new Error("upd_error")
        }

        if (data.personal_email) {
          response = await staffServiceActions.updateStaffContacts(
            data.email_id,
            1,
            data.personal_email,
            data.user_id,
          )

          if (response.status > 201) {
            processResponseError(response)
            throw new Error("upd_error")
          }
        }

        response = await staffServiceActions.updateStaffContacts(
          data.telegram_id,
          3,
          data.telegram,
          data.user_id,
        )

        if (response.status > 201) {
          processResponseError(response)
          throw new Error("upd_error")
        }

        if (data.requisites) {
          response = await staffServiceActions.updateStaffRequisite(data)
          if (response.status > 201) {
            processResponseError(response)
            throw new Error("upd_error")
          }
        }

        if (data.role_ids) {
          await patchUserRole(data.user_id, data.role_ids)
        }

        dispatch(setStaffEditSuccess(true))
      } catch (e: any /* eslint-disable-line @typescript-eslint/no-explicit-any */) {
        if (e.message === "upd_error") {
          dispatch(resetStoreStaffEdit())
        } else {
          dispatch(resetStoreStaffEdit())
          dispatch(setAppGlobalError({
            message: [ "" ],
            status: 500,
          }))
        }
      }
    },

    updateUserAvatar: async (userId: number, avatarId: number) => {
      try {
        let response = await staffServiceActions.updateStaffPersonalAvatar(userId, avatarId)
        if (response.status > 201) {
          processResponseError(response)
          throw new Error("upd_error")
        }
      } catch {
        dispatch(resetStoreStaffEdit())
      }
    },
  };
}
